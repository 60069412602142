//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'app-restore-scan-form-step1',
  props: ['loading'],
  data() {
    return {
      confirm: false,
    };
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
    }),
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    doOpenModal() {
      this.confirm = true
    },
    doCancel() {
      this.$emit('cancel');
    },
    async doSubmit() {
      this.confirm = false
      this.$emit('startScan');
    },
  },
};
