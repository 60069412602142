//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import RestoreFormScan from '@/modules/restore-items/components/2-restore-scan-form';
import { routerAsync } from '@/app-module';
import i18n from '@/vueI18n'
// import firebase from 'firebase/app';
// import 'firebase/firestore'

export default {
  name: 'app-restore-scan-page',

  components: {
    [RestoreFormScan.name]: RestoreFormScan,
  },

  data() {
    return {
      
    }
  },
  computed: {
    ...mapGetters({
      // record: 'restore/form/record',
      findLoading: 'restore/form/findLoading',
      // saveLoading: 'restore/form/saveLoading',
    }),
    language(){
      return i18n.locale
    }
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('entities.restore.label')  +
            ' | ' +
            this.i18n('entities.restore.pleaseScanItems') 
    },
  },

  // async creaded() {
  //   await firebase.firestore().collection('items').onSnapshot({ includeMetadataChanges: true }, async querySnapshot => {
  //      console.log(`Received query snapshot of size ${querySnapshot.size}`);
  //     this.doFetchItemsInBackground({ filter: { status: 'inStock' } })
  //   }, err => {
  //      console.log(`Encountered error: ${err}`);
  //   });
  // },

  methods: {
    ...mapActions({
      // doCreate: `restore/form/doCreate`,
      // doUpdate: `restore/form/doUpdate`,
      // doFetchSeller: 'seller/list/doFetch',
      doFetchItemsInBackground: 'restore/list/doFetchInBackground',
      doFetchItems: 'restore/list/doFetchIn',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    // doUpdate(payload) {
    //   return this.$store.dispatch(
    //     `${this.tab}/form/doUpdate`, payload
    //   );
    // },
    doCancel() {
      routerAsync().push('/restore');
    },

    async doSubmit() {
      
      // return this.doCreate(payload);
    },
  },
};
