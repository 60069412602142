//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { RestoreModel } from '@/modules/restore-items/restore-model';
// import { routerAsync } from '@/app-module';

import RestoreErrorModal from '@/modules/restore-items/components/modal/restore-error-modal';
import WritingNotesModal from '@/modules/restore-items/components/modal/writing-notes-modal';
// import RestoreSuccessfullyModal from '@/modules/restore-items/components/modal/restore-successfully-modal';
import RestoreFormScanStep1 from '@/modules/restore-items/components/3-restore-scan-form-step1';
import RestoreFormScanTable from '@/modules/restore-items/components/4-restore-scan-form-table';
// import firebase from 'firebase/app';
// import 'firebase/firestore'

const { fields } = RestoreModel; 
const formSchema = new FormSchema([
  fields.id,
  fields.itemId,
  fields.itemType,
  fields.serialNumber,
  fields.qrCode,
  fields.warrantyStatus,
  fields.status,
  fields.activationDate,
  fields.expirationDate,
  fields.sellerId,
  fields.exportedBy,
  fields.exportedAt,
  fields.activatedBy,
]);

export default {
  name: 'app-restore-scan-form',
  
  components: {
    [RestoreErrorModal.name]: RestoreErrorModal,
    [WritingNotesModal.name]: WritingNotesModal,
    [RestoreFormScanStep1.name]: RestoreFormScanStep1,
    [RestoreFormScanTable.name]: RestoreFormScanTable,
  },

  // async creaded() {
  //   this.unsubscribe = firebase.firestore().collection('items').where('status', '==', 'exported')
  //   .onSnapshot(async querySnapshot => {
  //     console.log(`Received query snapshot of size ${querySnapshot.size}`);
  //     this.doFetchExportedItems({ filter: { status: 'exported' } })
  //   }, err => {
  //      console.log(`Encountered error: ${err}`);
  //   });
  //   await this.doFetchExportedItemsInBackground({ filter: { status: 'exported' } })
  // },
  // beforeDestroy(){
  //   if(this.unsubscribe) this.unsubscribe()
  // },
  // async mounted() {
  //   await this.doFetchExportedItemsInBackground({ filter: { status: 'exported' } })
  // },

  data() {
    return {
      step: 1,
      model: null,
      // notes: null,
      restoreItemsTable: [],
      error_message: undefined,
      serialNumberInput: undefined,
      errorDialogVisible: false,
      errorModalVisible: false,
      notesDialogVisible: false,
      notesModalVisible: false,
      successDialogVisible: false,
      successModalVisible: false,
      rules: formSchema.rules(),
      unsubscribe: undefined,
    };
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      items: 'exporter/list/rows',
      itemsCount: 'exporter/list/count',
      itemsLoading: 'exporter/list/loading',
      saveLoading: 'restore/form/saveLoading',
    }),
    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doFetchExportedItemsInBackground: 'exporter/list/doFetchInBackground',
      doFetchExportedItems: 'exporter/list/doFetch',
      // doFetchItemsInBackground: 'restore/list/doFetchInBackground',
      // doFetchItems: 'restore/list/doFetch',
      // doFind: 'restore/form/doFind',
      // doNew: 'restore/form/doNew',
      // doCreate: `restore/form/doCreate`,
      // doUpdate: `restore/form/doUpdate`,
      doRestoreItems: `restore/form/doRestore`,
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    doReset() {
      this.model = formSchema.initialValues(this.record || {});
    },
    doOpenModal(type) {
      switch (type) {
        case 'error':
          this.errorModalVisible = true
          this.errorDialogVisible = true;
          break;
        case 'success':
          this.successModalVisible = true
          this.successDialogVisible = true;
          break;
        case 'notes':
          this.notesModalVisible = true;
          this.notesDialogVisible = true;
          break;
      }
    },
    onModalClose() {
      this.errorDialogVisible = false;
      this.successDialogVisible = false;
      this.notesDialogVisible = false
      this.error_message = undefined
      setTimeout(() => {
        this.errorModalVisible = false
        this.successModalVisible = false
        this.notesModalVisible = false
      }, 1000);
    },
    
    prev() { this.step > 1 ? this.step-- : this.$emit('cancel') },
    next() { this.step++ },
    
    doAddItemToCart() {
      let inRestoredTable = this.restoreItemsTable.filter(item => item.serialNumber.replace(/^0+/, '') == this.serialNumberInput.replace(/^0+/, ''))
      if (inRestoredTable.length) {
        this.serialNumberInput = undefined
        return
      }
      let item = this.items.filter(item => item.serialNumber.replace(/^0+/, '') == this.serialNumberInput.replace(/^0+/, ''))
      if (item.length) {
        this.restoreItemsTable.push(item[0])
        this.serialNumberInput = undefined
      } else {
        this.error_message = 'entities.restore.itemNotExist'
        this.doOpenModal('error')
      }
    },
    doRemoveItemFromCart(row) {
      const index = this.restoreItemsTable.indexOf(row)
      this.restoreItemsTable.splice(index, 1); 
    },
    doCancel() {
      this.$emit('cancel');
    },
    doTryAgain() {
      if (this.step === 1) {
        this.doCancel()
        this.onModalClose()
      } else {
        this.onModalClose()
        this.$refs.serialNumberInput.focus();
        this.serialNumberInput = undefined
      }
    },
    async doStartScan() {
      try {
        await this.doFetchExportedItems({ filter: { status: 'exported' } })
        return this.next();
      } catch (error) {
        this.error_message = error
        this.doOpenModal('error')
      }
    },
    async doSubmit(notes) {
      if (this.step == 1) {
        return this.doStartScan();
      }
     
      if (this.step == 2) {
        const values = this.restoreItemsTable
        const response = await this.doRestoreItems({
          notes: notes,
          values,
        });
        if (!response) return
        // return this.next() 
      }
    },
  },
  watch: {
    serialNumberInput() {
      if (this.serialNumberInput && this.serialNumberInput.length == 12) {
        this.doAddItemToCart()
      }
    },
  },
};
