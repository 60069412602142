//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
// import Message from '@/shared/message/message';

export default {
  name: 'app-writing-notes-modal',
  props: ['visible'],

  data() {
    return {
      notes: null,
    };
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      // settings:'settings/settings',
    }),
    dialogVisible: {
      get: function () {
        return this.visible;
      },

      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    ...mapActions({
      // doFindSettings:'settings/doFind',
      // isItemExist:'product/list/isItemExist'
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    doClose() {
      this.$emit('close')
    },
    async doSubmit() {
      await this.$emit('submit', this.notes)
      this.$emit('close')
    },
  },
};
