import { render, staticRenderFns } from "./4-restore-scan-form-table.vue?vue&type=template&id=3e5ab3e9&scoped=true&"
import script from "./4-restore-scan-form-table.vue?vue&type=script&lang=js&"
export * from "./4-restore-scan-form-table.vue?vue&type=script&lang=js&"
import style0 from "./4-restore-scan-form-table.vue?vue&type=style&index=0&id=3e5ab3e9&lang=scss&scoped=true&"
import style1 from "./4-restore-scan-form-table.vue?vue&type=style&index=1&id=3e5ab3e9&lang=scss&scoped=true&"
import style2 from "./4-restore-scan-form-table.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e5ab3e9",
  null
  
)

export default component.exports
import {QTable,QTr,QTh,QTd,QBtn,QTooltip,QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QTable,QTr,QTh,QTd,QBtn,QTooltip,QIcon})
