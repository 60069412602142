//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'app-restore-error-modal',
  props: ['visible', 'message'],


  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
    }),
    dialogVisible: {
      get: function () {
        return this.visible;
      },

      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    doClose() {
      this.$emit('close')
    },
    async doSubmit() {
      await this.$emit('submit')
    },
  },
};
