import { render, staticRenderFns } from "./writing-notes-modal.vue?vue&type=template&id=69ac5d59&scoped=true&"
import script from "./writing-notes-modal.vue?vue&type=script&lang=js&"
export * from "./writing-notes-modal.vue?vue&type=script&lang=js&"
import style0 from "./writing-notes-modal.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./writing-notes-modal.vue?vue&type=style&index=1&id=69ac5d59&lang=scss&scoped=true&"
import style2 from "./writing-notes-modal.vue?vue&type=style&index=2&id=69ac5d59&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69ac5d59",
  null
  
)

export default component.exports
import {QDialog,QCard,QCardSection,QIcon,QInput,QCardActions,QBtn,QField,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QCardSection,QIcon,QInput,QCardActions,QBtn,QField})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
