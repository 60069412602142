import { render, staticRenderFns } from "./3-restore-scan-form-step1.vue?vue&type=template&id=f8e9fbe4&scoped=true&"
import script from "./3-restore-scan-form-step1.vue?vue&type=script&lang=js&"
export * from "./3-restore-scan-form-step1.vue?vue&type=script&lang=js&"
import style0 from "./3-restore-scan-form-step1.vue?vue&type=style&index=0&id=f8e9fbe4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8e9fbe4",
  null
  
)

export default component.exports
import {QBtn,QSpinnerIos,QDialog} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn,QSpinnerIos,QDialog})
